import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone'
import DisplaySettingsTwoToneIcon from '@mui/icons-material/DisplaySettingsTwoTone'
import { lazyImport } from 'src/hooks/LazyImport'
import { ProtectedRoute } from 'src/types/Routes'
import { Roles } from './roles'

const { ExtendTrialPage } = lazyImport(
  () => import('src/pages/dashboard/ExtendTrial'),
  'ExtendTrialPage'
)
const { GetClientByDomainPage } = lazyImport(
  () => import('src/pages/dashboard/GetClientByDomainName'),
  'GetClientByDomainPage'
)
const { ChangeDomainOwnerPage } = lazyImport(
  () => import('src/pages/dashboard/ChangeDomainOwner'),
  'ChangeDomainOwnerPage'
)
const { GetAccountInfoPage } = lazyImport(
  () => import('src/pages/dashboard/GetAccountInfo'),
  'GetAccountInfoPage'
)
const { CancelSubscriptionPage } = lazyImport(
  () => import('src/pages/dashboard/CancelSubscription'),
  'CancelSubscriptionPage'
)

const { GetClientBySubscriptionPage } = lazyImport(
  () => import('src/pages/dashboard/GetClientBySubscription'),
  'GetClientBySubscriptionPage'
)
const { ControlGoogleConnectionPage } = lazyImport(
  () => import('src/pages/dashboard/ControlGoogleConnection'),
  'ControlGoogleConnectionPage'
)

export const ProtectedRoutes: ProtectedRoute[] = [
  {
    name: 'Extent trial',
    icon: <AccountCircleTwoToneIcon />,
    route: '/dashboard/extend-trial',
    path: 'extend-trial/*',
    element: <ExtendTrialPage />,
    permissions: [Roles['cheq-admin-t1']],
  },
  {
    name: 'Get client by domain',
    icon: <DisplaySettingsTwoToneIcon />,
    route: '/dashboard/get-client-by-domain',
    path: 'get-client-by-domain/*',
    element: <GetClientByDomainPage />,
    permissions: [Roles['cheq-admin-t1']],
  },
  {
    name: 'Change domain owner',
    icon: <DisplaySettingsTwoToneIcon />,
    route: '/dashboard/change-domain-owner',
    path: 'change-domain-owner/*',
    element: <ChangeDomainOwnerPage />,
    permissions: [Roles['cheq-admin-t1']],
  },
  {
    name: 'Get account info',
    icon: <DisplaySettingsTwoToneIcon />,
    route: '/dashboard/get-account-info',
    path: 'get-account-info/*',
    element: <GetAccountInfoPage />,
    permissions: [Roles['cheq-admin-t1']],
  },
  {
    name: 'Cancel subscription',
    icon: <DisplaySettingsTwoToneIcon />,
    route: '/dashboard/cancel-subscription',
    path: 'cancel-subscription/*',
    element: <CancelSubscriptionPage />,
    permissions: [Roles['cheq-admin-t2']],
  },
  {
    name: 'Get client by subscription',
    icon: <DisplaySettingsTwoToneIcon />,
    route: '/dashboard/get-client-by-subscription',
    path: 'get-client-by-subscription/*',
    element: <GetClientBySubscriptionPage />,
    permissions: [Roles['cheq-admin-t1']],
  },
  // { // TODO: uncomment when ready
  //   name: 'Control Google connection',
  //   icon: <DisplaySettingsTwoToneIcon />,
  //   route: '/dashboard/control-google-connection',
  //   path: 'control-google-connection/*',
  //   element: <ControlGoogleConnectionPage />,
  //   permissions: [Roles['cheq-admin-t1']],
  // },
]
