import { QueryClientProvider } from '@tanstack/react-query'

import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'

import { CssBaseline } from '@mui/material'
import ThemeProvider from 'src/theme/ThemeProvider'
import { queryClient } from 'src/lib/react-query'
import { RoutesDisplay } from './HOC/Routes'

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline />
          <RoutesDisplay />
        </LocalizationProvider>
      </ThemeProvider>
    </QueryClientProvider>
  )
}
export default App
