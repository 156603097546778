import { Box, styled } from '@mui/material'
import { CCLogo } from 'src/assets/icons/CCLogo'
import { Link } from 'react-router-dom'

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        margin: 0 auto;
        font-weight: ${theme.typography.fontWeightBold};
`
)

function Logo({ height = 40 }: { height?: number }) {
  const setLogoSize = () => {
    const ratio = 6.76
    return { height: `${height}px`, width: `${height * ratio}px` }
  }
  
  return (
    <LogoWrapper to="/overview">
      <Box {...setLogoSize()} marginBottom=".5rem">
        <CCLogo {...setLogoSize()} />
      </Box>
    </LogoWrapper>
  )
}

export default Logo
