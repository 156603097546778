import { create } from 'zustand'
import { LoginParamsType } from 'src/types/LoginParams'
import { getCookie } from 'typescript-cookie'
import { COOKIE_NAMES } from 'src/consts/'

export interface UserStore {
  isLoggedIn: boolean
  token: string
  authCode: string
  adminRole: string
  email: string
  errorMsg: string | null
  logIn: ({ token, authCode, adminRole, email }: LoginParamsType) => void
  logOut: () => void
  setToken: (token: string) => void
  setErrorMsg: (error: string | null) => void
}

export const useUserStore = create<UserStore>((set) => ({
  isLoggedIn: false,
  errorMsg: null,
  token: getCookie(COOKIE_NAMES.token) || '',
  authCode: getCookie(COOKIE_NAMES.authCode) || '',
  adminRole: getCookie(COOKIE_NAMES.adminRole) || '',
  email: getCookie(COOKIE_NAMES.email) || '',
  logIn: ({ token, authCode, adminRole, email }) => {
    set(() => ({
      token,
      authCode,
      adminRole,
      isLoggedIn: true,
      email,
    }))
  },
  logOut: () => {
    set(() => ({
      token: '',
      authCode: '',
      adminRole: '',
      isLoggedIn: false,
      email: '',
    }))
  },
  setToken: (token) => {
    set(() => ({
      token,
    }))
  },
  setErrorMsg: (errorMsg) => {
    set(() => ({
      errorMsg,
    }))
  },
}))

export default useUserStore
