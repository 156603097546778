import { create } from 'zustand'

type TosterType = {
  type?: 'success' | 'error' | 'warning' | 'info'
  show?: boolean
  text?: string
}

export interface AppStore {
  isLoading: boolean
  errorMsg: string | null
  toaster: TosterType
  setLoader: (isLoading: boolean) => void
  setErrorMsg: (error: string | null) => void
  showToaster: ({ type, text }: TosterType) => void
  hideToaster: () => void
}

export const useAppStore = create<AppStore>((set) => ({
  isLoading: false,
  errorMsg: null,
  toaster: {
    type: 'success',
    show: false,
    text: '',
  },
  setLoader: (isLoading) => {
    set(() => ({
      isLoading,
    }))
  },
  setErrorMsg: (errorMsg) => {
    set(() => ({
      errorMsg,
    }))
  },
  showToaster: ({ type, text }) => {
    set(() => ({
      toaster: { type, text, show: true },
    }))
  },
  hideToaster: () => {
    set(() => ({
      toaster: { show: false },
    }))
  },
}))

export default useAppStore
