import { Roles, RolesName } from 'src/consts/roles'

export function isRouteAllowed(role: string, routePremissions: string[]) {
  role = role.toLowerCase()
  if (routePremissions.includes(role) || role === Roles['cheq-admin-t4']) return true
  else {
    let allowRoles: Array<string>
    switch (role) {
      case Roles['cheq-admin-t2']:
        allowRoles = [
          Roles['cheq-admin-t1'],
          Roles['cheq-admin-t2'],
          Roles['marketing'],
          Roles['plg'],
        ]
        break
      case Roles['cheq-admin-t1']:
        allowRoles = [Roles['cheq-admin-t1'], Roles['marketing'], Roles['plg']]
        break
      case Roles['marketing']:
        allowRoles = [Roles['marketing']]
        break
      case Roles['plg']:
        allowRoles = [Roles['plg']]
        break
      default:
        allowRoles = []
    }
    return allowRoles.some((_role) => routePremissions.includes(_role))
  }
}

export function getRoleName(role: string) {
  return RolesName[role.toLowerCase()] ?? ''
}
