import React, { useMemo } from 'react'
import { RouteObject, useRoutes } from 'react-router'
import useUserStore from 'src/stores/user'
import { publicRoutes } from './public.routes'
import { getProtectedRoutes } from './protected.routes'

export function RoutesDisplay() {
  const { adminRole } = useUserStore()
  const protectedRoutes = useMemo(() => getProtectedRoutes(adminRole), [adminRole])
  const routes: RouteObject[] = [publicRoutes, protectedRoutes]

  const content = useRoutes(routes)
  return <>{content}</>
}
