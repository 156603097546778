import { SidebarLayout } from 'src/layouts/SidebarLayout'
import { ProtectedRoutes } from 'src/consts/protectedRoutes'
import { isRouteAllowed } from 'src/utils/roles'
import { ProtectedRoute } from 'src/types/Routes'
import { Wrapper } from 'src/components/Wrapper'

const getProtectedChildrenArray = (adminRole: string) =>
  ProtectedRoutes.map((route: ProtectedRoute) =>
    isRouteAllowed(adminRole, route.permissions)
      ? {
          path: route.path,
          element: <Wrapper />,
          children: [{ path: '*', element: route.element }],
        }
      : null
  ).filter((route) => route !== null)

export const getProtectedRoutes = (adminRole: string) => ({
  path: 'dashboard',
  element: <SidebarLayout />,
  children: !!adminRole && getProtectedChildrenArray(adminRole),
})
