export const Roles = {
  'cheq-admin-t1': 'cheq-admin-t1',
  'cheq-admin-t2': 'cheq-admin-t2',
  'cheq-admin-t3': 'cheq-admin-t3',
  'cheq-admin-t4': 'cheq-admin-t4',
  marketing: 'marketing',
  plg: 'plg',
}

export const RolesName = {
  'cheq-admin-t1': 'Admin t1',
  'cheq-admin-t2': 'Admin t2',
  'cheq-admin-t3': 'Admin t3',
  'cheq-admin-t4': 'Super Admin',
  marketing: 'Marketing',
  plg: 'PLG',
}
