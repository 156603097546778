import Axios, { AxiosRequestConfig } from 'axios'
import { getCookie } from 'typescript-cookie'
import { useAppStore } from 'src/stores/app'
import { $t } from 'src/utils/dictionary'

const { showToaster } = useAppStore.getState()

function authRequestInterceptor(config: AxiosRequestConfig) {
  const UNDEFINED = 'undefined'
  const token = getCookie('jwtToken')
  const authCode = getCookie('authCode')

  if (token && authCode && token != UNDEFINED && authCode != UNDEFINED) {
    config.headers.Authorization = `Bearer ${token}`
    config.headers['x-auth-code'] = authCode
  }
  config.headers.Accept = 'application/json'
  return config
}

export const axios = Axios.create({
  baseURL: process.env.REACT_APP_CC_CORE,
})

axios.interceptors.request.use(authRequestInterceptor)

export const request = async (callback: () => any) => {
  try {
    const response = await callback()
    const { data } = response
    return { data: data.data || data, success: true }
  } catch (error) {
    const msg = error.response.data.message ?? error.message
    showToaster({ type: 'error', text: $t(msg) })
    return { success: false, massage: error?.response?.data?.message ?? error.message }
  }
}
