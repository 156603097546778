import { useEffect, Suspense } from 'react'
import { Outlet } from 'react-router'
import useUserStore from 'src/stores/user'
import { useLogout } from 'src/utils/user-connection'
import ApiLoader from '../ApiLoader'

export const Wrapper = () => {
  const { logOutUser } = useLogout()
  const { token } = useUserStore()

  useEffect(() => {
    if (!token) {
      logOutUser()
    }
  }, [logOutUser])

  return (
    <Suspense fallback={<ApiLoader />}>
      <Outlet />
    </Suspense>
  )
}
