import * as React from 'react'
import { Snackbar, Alert } from '@mui/material'
import { useAppStore } from 'src/stores/app'

const Toaster = () => {
  const { toaster, hideToaster } = useAppStore()
  const { text, type, show } = toaster

  return (
    <Snackbar
      open={show}
      autoHideDuration={4000}
      onClose={hideToaster}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Alert onClose={hideToaster} severity={type} sx={{ width: '100%' }}>
        {text}
      </Alert>
    </Snackbar>
  )
}

export default Toaster
