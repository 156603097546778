import { FC, ReactNode, Suspense } from 'react'
import PropTypes from 'prop-types'
import { Outlet } from 'react-router-dom'

import { Box } from '@mui/material'
import ApiLoader from 'src/components/ApiLoader'

interface BaseLayoutProps {
  children?: ReactNode
}

export const BaseLayout: FC<BaseLayoutProps> = ({ children }) => (
  <Box
    sx={{
      flex: 1,
      height: '100%',
    }}
  >
    <Suspense fallback={<ApiLoader />}>
      {children || <Outlet />}
      <ApiLoader />
    </Suspense>
  </Box>
)

BaseLayout.propTypes = {
  children: PropTypes.node,
}
