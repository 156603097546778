import { removeCookie, setCookie } from 'typescript-cookie'
import { useUserStore } from 'src/stores/user'
import { useNavigate } from 'react-router-dom'
import { LoginFormType } from 'src/types/LoginParams'
import { sendLogin } from 'src/api/user'
import { COOKIE_NAMES } from 'src/consts/index'

export const useLogout = () => {
  const { logOut } = useUserStore()
  const navigate = useNavigate()
  const logOutUser = () => {
    logOut()
    removeCookie(COOKIE_NAMES.token)
    removeCookie(COOKIE_NAMES.authCode)
    removeCookie(COOKIE_NAMES.adminRole)
    removeCookie(COOKIE_NAMES.email)
    navigate('/login')
  }
  return { logOutUser }
}

export const useLogin = () => {
  const { logIn } = useUserStore()
  const navigate = useNavigate()
  const loginUser = async (form: LoginFormType) => {
    const response = await sendLogin(form)
    if (response.success) {
      const { token, authCode, adminRole } = response.data
      setCookie(COOKIE_NAMES.token, token, { expires: 0.5, secure: true })
      setCookie(COOKIE_NAMES.authCode, authCode)
      setCookie(COOKIE_NAMES.adminRole, adminRole)
      setCookie(COOKIE_NAMES.email, form.email)
      logIn({ ...response.data, email: form.email })
      navigate('/dashboard/extend-trial')
    }
    return response
  }
  return { loginUser }
}
